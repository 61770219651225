@font-face {
  font-family: RobotoCondensed;
  src: url(assets/roboto-condensed.light.ttf);
  font-weight: 800;
}

@font-face {
  font-family: EconomicaBold;
  src: url(assets/economica-bold.ttf);
}

body {
  text-align: center;
  font-size: 14px;
  padding: 2.5%;
  font-family: 'RobotoCondensed';
}

h1, h2, h3, h4, h5 {
  font-family: 'EconomicaBold';
}

.track {
  width: 250px;
  height: 250px;
  position: relative;
  display: inline-block;
  margin: 20px;
  cursor: pointer;
}

.track-image {
  width: 250px;
  height: 250px;
}

.track-text {
  position: absolute;
  width: 250px;
  bottom: 0px;
  background-color: black;
  color: white;
  padding: 5px;
}

.track-icon {
  position: absolute;
  bottom: 100px;
  left: 100px;
  color: white;
  background-color: black;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  padding: 10px;
  opacity: 0.8;
}