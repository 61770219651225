@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap');

:root {
  --primary-color: #22254b;
  --secondary-color: #373b69;
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--primary-color);
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

header {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  background-color: var(--secondary-color);
}

.form {
  padding: 2rem;
}

button {
  background-color: lightblue;
  outline: none;
}

h1 {
  color: #22254b;
  padding-left: 2rem;
}

h3, span {
  padding-left: 0.25rem;
}

.search {
  background-color: transparent;
  border: 2px solid #22254b;
  border-radius: 50px;
  font-family: inherit;
  font-size: 2rem;
  padding: 0.5rem 2rem;
  color: #fff;
}

.search::placeholder {
  color: var(--primary-color);
}

.search:focus {
  outline: none;
  background-color: var(--primary-color);
}

.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.movie {
  width: 300px;
  margin: 1rem;
  background-color: var(--secondary-color);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}

.movie img {
  width: 100%;
}

.movie-info {
  color: #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem 1rem;
  letter-spacing: 0.5px;
}

.movie-info h3 {
  margin-top: 0;
}

.movie-info span {
  background-color: #22254b;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  font-weight: bold;
}

span.green {
  color: lightgreen;
}

span.orange {
  color: orange;
}

span.red {
  color: red;
}

.overview {
  background-color: #fff;
  padding: 2rem;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 100%;
  transform: translateY(101%);
  overflow-y: auto;
  transition: transform 0.3s ease-in;
}

.movie:hover .overview {
  transform: translateY(0);
}

#credits {
  background-color: #fff;
  color: #000;
  font-weight: bold;
  padding: 0.5px;
  display: none;
}

#about:hover + #credits {
  display: block;
}